<template>
  <div class="text-center">
    <template v-if="checkPermission(permissionUpdate)">
      <button
        @click="editData()"
        class="btn btn-sm btn-clean btn-icon btn-icon-md"
        title="Edit"
      >
        <i class="la la-pencil-square"></i>
      </button>
    </template>
    <template v-if="checkPermission(permissionDelete)">
      <button
        @click="deleteData()"
        class="btn btn-sm btn-clean btn-icon btn-icon-md"
        title="Hapus"
      >
        <i class="la la-trash"></i>
      </button>
    </template>
  </div>
</template>

<script>
import CheckPermission from "../../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
const KelasRepository = RepositoryFactory.get("kelas");
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  data() {
    return {
      permissionUpdate: 'KelasResource.PUT.Kelas',
      permissionDelete: 'KelasResource.DELETE.Kelas.id'
    }
  },
  props: {
    rowData: { type: Object },
    storeObject: {}
  },
  computed: {
    dataEdit: function () {
      return this.storeObject.state.MasterStore.dataEdit;
    }
  },
  methods: {
    deleteData() {
      this.storeObject.dispatch("MasterStore/deleteById", { deleteRepository: KelasRepository.deleteKelas, id: this.rowData.id });
    },
    editData() {
      this.storeObject.commit("MasterStore/SET_EDIT_DATA", this.rowData);
      this.storeObject.commit("MasterStore/SHOW_MODAL");
    },
    checkPermission: function (method) {
      return checkPermission.isCanAccess(method);
    }
  },
  mounted() {
  }
};
</script>
